.react-autosuggest__container {
  position: relative;
  flex: 1;
}

.react-autosuggest__input {
  padding: 10px 20px;
  font-family: inherit;
  border: none;
  outline: none;
  transition-duration: 0.2s;
  transition-property: color, border-color;
  border-bottom: 1px solid #e5e5e5;
  width: calc(100% - 40px);
}

.react-autosuggest__input--focused {
  outline: none;
  border-color: #337ab7;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  display: block;
  position: absolute;
  min-width: 100%;
  background-color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  z-index: 2;
  top: 30px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  font-size: 13px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #d2deea;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  color: #777;
}
